import { Link, graphql, useStaticQuery } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import DemoButton from "../demoButton/demoButton";
import * as s from "./contentWithImage.module.css";

const ContentWithImage = ({ block, scrollToForm }) => {
  const bp = useBreakpoint();


  const { wp: { acfOptionsQrMenuMaker: { qrMenuMaker: { qrMenuMakerBlock: qrMenuData } } } } = useStaticQuery(graphql`
    query {
      wp {
        acfOptionsQrMenuMaker {
          qrMenuMaker {
            qrMenuMakerBlock {
              title
              subtitle
              cta {
                url
                title
                target
              }
              downloadText
              googlePlayLink {
                url
                title
                target
              }
              googlePlayLogo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              appStoreLink {
                url
                title
                target
              }
              appStoreLogo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              imageDesktop {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              imageMobile {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const googlePlayLogo = {
    data: qrMenuData.googlePlayLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: qrMenuData?.googlePlayLogo?.altText || ``,
  }

  const appStoreLogo = {
    data: qrMenuData?.appStoreLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: qrMenuData?.appStoreLogo?.altText || ``,
  }

  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  const imageAfterItems = {
    data: block.imageAfterItems?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.imageAfterItems?.altText || ``,
  };

  return (
    <>
      <section className={`${s.contentWithImageBlock} ${!!block?.vertical ? s.vertical : ''}`}>
        <div className="container">
          <div className={`row align-items-center`}>
            {(!!block?.reverse && bp?.lg) &&
              <div className="col-lg-6">
                {!!image?.data && <GatsbyImage
                  placeholder="none"
                  loading="eager"
                  image={image.data}
                  alt={image.alt}
                  className="mw-100" />
                }
              </div>
            }
            <div className="col-lg-6">
              {!!block?.badge && <div className={`d-flex justify-content-start`}><div className="blue-badge">{parse(block?.badge)}</div></div>}
              {!!block?.title && <h2 className={`gradient-title ${s.title}`}>{parse(block?.title)}</h2>}
              {!!block?.description && <div className={`${s.description}`}>{parse(block?.description)}</div>}
              {!!block?.itemsTitle && <div className={`${s.itemsTitle}`}>{parse(block?.itemsTitle)}</div>}
              {!!block?.items?.length > 0 && <>
                <div className={`${s.items}`}>
                  {block?.items.map((item, i) => {
                    switch (item.type) {
                      case 'withIcon':
                        return <ItemWithIcon item={item} key={`item-${i}`} />
                      case 'titleOnly':
                        return <ItemTitleOnly item={item} key={`item-${i}`} />
                      default:
                        break;
                    }
                  })}
                </div>
              </>}
              {!!imageAfterItems?.data && <div className="mb-3 mb-md-4">
                <GatsbyImage
                  placeholder="none"
                  loading="eager"
                  image={imageAfterItems.data}
                  alt={imageAfterItems.alt}
                  className="mw-100" />
              </div>
              }
              {(bp?.lg && !!block?.button?.url) && <>
                {'#contactForm' == block?.button?.url
                  ? <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>{block?.button?.title}</DemoButton>
                  : <Link to={block?.button?.url} className={'demo-button'} target={block?.button?.target}>{block?.button?.title}</Link>}
              </>
              }
              {(bp?.lg && !!block?.useQrMenuMakerButtons) && <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start" style={{ gap: '15px' }}>
                {!!qrMenuData?.cta?.url && <Link to={qrMenuData?.cta?.url} target={qrMenuData?.cta?.target} className={'cta-btn'}>{qrMenuData?.cta?.title}</Link>}
                <div>
                  {!!qrMenuData?.downloadText && <div className="download-text">{parse(qrMenuData?.downloadText)}</div>}
                  <div className="d-flex flex-wrap" style={{ gap: '8px' }}>
                    {!!qrMenuData?.googlePlayLink?.url &&
                      <Link to={qrMenuData?.googlePlayLink?.url} target={qrMenuData?.googlePlayLink?.target} className={'download-btn'}>
                        {!!googlePlayLogo?.qrMenuData
                          ? <GatsbyImage
                            placeholder="none"
                            loading="eager"
                            image={googlePlayLogo.data}
                            alt={googlePlayLogo.alt}
                          />
                          : <StaticImage
                            placeholder="none"
                            loading="eager"
                            alt="playMarket"
                            src="../qrMenuMaker/img/playmarket.png"
                          />
                        }
                      </Link>
                    }
                    {!!qrMenuData?.appStoreLink?.url &&
                      <Link to={qrMenuData?.appStoreLink?.url} target={qrMenuData?.appStoreLink?.target} className={'download-btn'}>
                        {!!appStoreLogo?.data
                          ? <GatsbyImage
                            placeholder="none"
                            loading="eager"
                            image={appStoreLogo.data}
                            alt={appStoreLogo.alt}
                          />
                          : <StaticImage
                            placeholder="none"
                            loading="eager"
                            alt="playMarket"
                            src="./img/appstore.png"
                          />
                        }
                      </Link>
                    }
                  </div>
                </div>
              </div>}
            </div>
            {bp?.lg
              ? <>{!block?.reverse && <div className="col-lg-6 text-right">
                {!!image?.data && <GatsbyImage
                  placeholder="none"
                  loading="eager"
                  image={image.data}
                  alt={image.alt}
                  className="mw-100" />
                }
              </div>}</>
              : <div className="col-lg-6 text-center">
                {!!image?.data && <GatsbyImage
                  placeholder="none"
                  loading="eager"
                  image={image.data}
                  alt={image.alt}
                  className="mw-100" />
                }
                {(!bp?.lg && !!block?.button?.url) && <div className="d-flex justify-content-center mt-2">
                  {'#contactForm' == block?.button?.url
                    ? <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>{block?.button?.title}</DemoButton>
                    : <Link to={block?.button?.url} className={'demo-button'} target={block?.button?.target}>{block?.button?.title}</Link>}
                </div>}
                {(!bp?.lg && !!block?.useQrMenuMakerButtons) && <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start mt-2" style={{ gap: '15px' }}>
                  {!!qrMenuData?.cta?.url && <Link to={qrMenuData?.cta?.url} target={qrMenuData?.cta?.target} className={'cta-btn'}>{qrMenuData?.cta?.title}</Link>}
                  <div>
                    {!!qrMenuData?.downloadText && <div className="download-text">{parse(qrMenuData?.downloadText)}</div>}
                    <div className="d-flex flex-wrap" style={{ gap: '8px' }}>
                      {!!qrMenuData?.googlePlayLink?.url &&
                        <Link to={qrMenuData?.googlePlayLink?.url} target={qrMenuData?.googlePlayLink?.target} className={'download-btn'}>
                          {!!googlePlayLogo?.qrMenuData
                            ? <GatsbyImage
                              placeholder="none"
                              loading="eager"
                              image={googlePlayLogo.data}
                              alt={googlePlayLogo.alt}
                            />
                            : <StaticImage
                              placeholder="none"
                              loading="eager"
                              alt="playMarket"
                              src="../qrMenuMaker/img/playmarket.png"
                            />
                          }
                        </Link>
                      }
                      {!!qrMenuData?.appStoreLink?.url &&
                        <Link to={qrMenuData?.appStoreLink?.url} target={qrMenuData?.appStoreLink?.target} className={'download-btn'}>
                          {!!appStoreLogo?.data
                            ? <GatsbyImage
                              placeholder="none"
                              loading="eager"
                              image={appStoreLogo.data}
                              alt={appStoreLogo.alt}
                            />
                            : <StaticImage
                              placeholder="none"
                              loading="eager"
                              alt="playMarket"
                              src="./img/appstore.png"
                            />
                          }
                        </Link>
                      }
                    </div>
                  </div>
                </div>}
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

const ItemWithIcon = ({ item }) => {
  let icon = {
    src: item.icon?.localFile?.publicURL,
    data: item.icon?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.icon?.altText || ``,
  }
  return <div className={`${s.item} ${s.iconItem}`}>
    {!!icon?.data
      ? <div className={s.itemIcon}>
        <GatsbyImage
          placeholder="none"
          loading="eager"
          image={icon.data}
          alt={icon.alt}
          className="mw-100" />
      </div>
      : <>
        {!!icon?.src && <div className={s.itemIcon}>
          <img src={icon?.src} alt={icon.alt} className="mw-100" />
        </div>}
      </>}
    {!!item?.title && <div className={s.itemTitle}>{parse(item?.title)}</div>}
    {!!item?.description && <div className={s.itemDescription}>{parse(item?.description)}</div>}
  </div>
}

const ItemTitleOnly = ({ item }) => {
  let icon = {
    src: item.icon?.localFile?.publicURL,
    data: item.icon?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.icon?.altText || ``,
  }
  return (<div className={`${s.item} ${s.titleItem}`}>
    {!!icon?.data
      ? <div className={s.itemIcon}>
        <GatsbyImage
          placeholder="none"
          loading="eager"
          image={icon.data}
          alt={icon.alt}
          className="mw-100" />
      </div>
      : <>
        {!!icon?.src && <div className={s.itemIcon}>
          <img src={icon?.src} alt={icon.alt} className="mw-100" />
        </div>}
      </>}
    {!!item?.title && <div className={s.itemTitle}>{parse(item?.title)}</div>}
    {!!item?.description && <div className={s.itemDescription}>{parse(item?.description)}</div>}
  </div>
  )
}

export default ContentWithImage;
