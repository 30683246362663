import parse from "html-react-parser";
import React from "react";
import * as s from "./values.module.css";

const ValuesBlock = ({ block }) => {
  return (
    <>
      <section className={s.questionBlock}>
        {!!block?.title && <div className={`${s.valueTitle} title-block container`}>
          <h2 className={`text-center gradient-title`}>{parse(block?.title)}</h2>
        </div>}

        {block?.items?.length > 0 &&
          <div className="container">
            <div className="">
              {block?.items?.map((item, i) => {
                return <div className={`${s.questionTile}`} key={i}>
                  <div className={s.questionNumber}>
                    <h2>{i + 1}</h2>
                  </div>
                  <div className={s.ourQuestion}>
                    <p>{parse(item.text)}</p>
                  </div>
                </div>
              })}
            </div>
          </div>
        }
      </section>
    </>
  );
};

export default ValuesBlock;
