import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import * as s from "./partnersIcons.module.css";

const PartnersIcons = () => {

  const sliderSettings = {
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    initialSlide: 1,
    dots: false,
    arrows: false,
    draggable: false,
  }

  const { wp: { acfOptionsPartners: { partnersIcons: { partnersIconsBlock } } } } = useStaticQuery(graphql`
      query MyOptionsPartnersQuery {
        wp {
          acfOptionsPartners {
            partnersIcons {
              partnersIconsBlock {
                title
                icons {
                  link {
                    url
                    title
                    target
                  }
                  icon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);

  return (
    <>
      <section className={`${s.partners} `}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!partnersIconsBlock?.title && <h2 className={s.title}>{parse(partnersIconsBlock.title)}</h2>}
            </div>
          </div>
        </div>
        <div className="container-fluid px-0">
          <Slider {...sliderSettings}>
            {partnersIconsBlock?.icons?.length > 0 && partnersIconsBlock?.icons?.map((item, i) => {
              return <PartnersItem item={item} key={i} />
            })}
          </Slider>
        </div>
      </section >
    </>
  );
};

export default PartnersIcons;

const PartnersItem = ({ item }) => {
  const icon = {
    data: item.icon?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.icon?.altText || ``,
  }
  if (!icon?.data) {
    return <></>;
  }
  if (!!item?.link?.url) {
    return <Link to={item?.link?.url} className={s.partnersItem}>
      <GatsbyImage
        placeholder="none"
        loading="eager"
        image={icon.data}
        alt={icon.alt}
        className="w-auto" />
    </Link>
  }
  return <span className={s.partnersItem}>
    <GatsbyImage
      placeholder="none"
      loading="eager"
      image={icon.data}
      alt={icon.alt}
      className="w-auto" />
  </span>
}

