import parse from "html-react-parser";
import React from "react";
import * as s from "./pricing.module.css";

const Pricing = ({ block }) => {
  return (
    <>
      <section className={`${s.pricing}`}>
        <div className="container position-ralative">
          <div className={`${s.heading} row`}>
            <div className="col-3 pr-0"><h3 style={{ color: 'black' }}>{parse(block?.featuresLabel)}</h3></div>
            <div className="col-3 text-center pl-0">
              <h3>{parse(block?.essentialLabel)}</h3>
              <div className={s.price}>{parse(block?.essentialPrice)}</div>
            </div>
            <div className="col-3 text-center px-1 px-md-2">
              <h3>{parse(block?.professionalLabel)}</h3>
              <div className={s.price}>{parse(block?.professionalPrice)}</div>
            </div>
            <div className="col-3 text-center pr-0">
              <h3>{parse(block?.growthLabel)}</h3>
              <div className={s.price}>{parse(block?.growthPrice)}</div>
            </div>
          </div>

          {block?.features?.map((item, i) => {
            return (<div className={`row ${s.pricingRow}`} key={i}>
              <div className="col-3 pr-0">{parse(item.name)}</div>
              <div className="col-3 text-center pl-0"><ListValue type={item.essential} text={item.essentialText} /></div>
              <div className="col-3 text-center px-1 px-md-2"><ListValue type={item.professional} text={item.professionalText} /></div>
              <div className="col-3 text-center pr-0"><ListValue type={item.growth} text={item.growthText} /></div>
            </div>
            )
          })}

        </div>
      </section>
    </>
  );
};

const ListValue = ({ type, text }) => {
  switch (type) {
    case 'text':
      return parse(text);
    case 'true':
      return <svg width="16" height="13" viewBox="0 0 16 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.472 2.036L13.436 0L5.497 7.94L2.036 4.479L0 6.514L5.497 12.011L15.472 2.036Z" fill="green"></path>
      </svg>
    case 'false':
      return <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1056 0.0015874L13 1.89216L1.87109 12.9984L8.17877e-08 11.0856L11.1056 0.0015874Z" fill="red"></path>
        <path d="M12.9968 11.1056L11.1063 13L0 1.87109L1.91286 0L12.9968 11.1056Z" fill="red"></path>
      </svg>
    default:
      return <></>
  }

}


export default Pricing;
