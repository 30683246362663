import { Link } from "gatsby";
import React from "react";
import DemoButton from "../demoButton/demoButton";

const CustomButtonBlock = ({ block, scrollToForm }) => {

  const classes = [];

  switch (block?.layout) {
    case 'outline':
      classes.push('more-link');
      break;
    case 'filledBlack':
    default:
      classes.push('demo-button');
      break;
  }
  return (
    <>
      <section className="my-5">
        <div className="container d-flex justify-content-center">
          {block.type == 'link' && <Link to={block?.link?.url} target={block?.link?.url} className={`px-3 ${classes.join(' ')}`}>{block?.link?.title}</Link>}
          {block.type == 'scrollToForm' && <DemoButton scrollToForm={scrollToForm} classes={classes}>{block?.buttonText}</DemoButton>}
        </div>
      </section>
    </>
  );
};

export default CustomButtonBlock;
