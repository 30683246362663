import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./located.module.css";

const LocatedBlock = ({ block }) => {

  const bp = useBreakpoint();

  const desktopImage = {
    data: block.desktopImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.desktopImage?.altText || ``,
  };

  const mobileImage = {
    data: block.mobileImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.mobileImage?.altText || ``,
  };

  const desktopGatsbyImage = !!desktopImage.data
    ? <GatsbyImage
      placeholder="none"
      loading="eager"
      image={desktopImage.data}
      alt={desktopImage.alt}
      className="mw-100" />
    : null;

  const mobileGatsbyImage = !!mobileImage.data
    ? <GatsbyImage
      placeholder="none"
      loading="eager"
      image={mobileImage.data}
      alt={mobileImage.alt}
      className="mw-100" />
    : desktopGatsbyImage;

  return (
    <>
      <section className={s.hero}>
        <div className="container">
          <div>
            {!!block.title && <h2 className={`gradient-title text-center mt-5 pt-md-5`}>
              {parse(block.title)}
            </h2>}
            <div className="w-100 position-relative">
              {bp?.md ?
                <>
                  {desktopGatsbyImage}
                </>
                : <>
                  {mobileGatsbyImage}
                </>
              }
            </div>
            {!!block?.description && <div className={s.description}>{parse(block?.description)}</div>}
          </div>
        </div>
      </section>
    </>
  );
};

export default LocatedBlock;
