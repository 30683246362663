import React from "react";
import * as s from "./faqquestion.module.css";

const FaqQuestion = ({ question, children, opened, handleOpened }) => {
  return (
    <div>
      <div className={`${opened ? s.collapsibleOpen : ""} ${s.accordionCollapsible}`}>
        <button type="button" className={s.collapsibleHeader} onClick={handleOpened}>
          <span className={s.question}>{question}</span>
          {opened ? <Up /> : <Down />}
        </button>
      </div>
      {opened &&
        <div className={s.answer}>
          <p>{children}</p>
        </div>
      }
    </div>
  );
};

const Up = () => {
  return <svg style={{ minWidth: '15px' }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7559 0.918701C12.5632 0.918701 13.1777 1.12443 13.5996 1.53589C14.0163 1.94735 14.2246 2.55672 14.2246 3.36401L14.2246 12.3406C14.2246 13.1479 14.0163 13.7572 13.5996 14.1687C13.1777 14.5854 12.5632 14.7937 11.7559 14.7937L2.81055 14.7937C1.99805 14.7937 1.38346 14.5854 0.966797 14.1687C0.550131 13.752 0.341798 13.1427 0.341798 12.3406L0.341798 3.36401C0.341798 2.56193 0.550132 1.95516 0.966799 1.5437C1.38347 1.12703 1.99805 0.9187 2.81055 0.9187L11.7559 0.918701ZM7.67774 4.9812C7.58399 4.82495 7.44336 4.74422 7.25586 4.73901C7.06836 4.7338 6.92513 4.81453 6.82617 4.9812L4.24024 9.40308C4.13607 9.58016 4.12044 9.75203 4.19336 9.9187C4.26628 10.0906 4.39649 10.1765 4.58399 10.1765L9.91992 10.1765C10.1074 10.1765 10.2376 10.088 10.3105 9.91089C10.3835 9.73901 10.3704 9.56974 10.2715 9.40308L7.67774 4.9812Z" fill="#A3A3A3" />
  </svg>
}

const Down = () => {
  return <svg style={{ minWidth: '15px' }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.45898 14.3899C2.65169 14.3899 2.03711 14.1842 1.61523 13.7727C1.19857 13.3612 0.990234 12.7519 0.990234 11.9446V2.96802C0.990234 2.16073 1.19857 1.55135 1.61523 1.13989C2.03711 0.723226 2.65169 0.514893 3.45898 0.514893H12.4043C13.2168 0.514893 13.8314 0.723226 14.248 1.13989C14.6647 1.55656 14.873 2.16593 14.873 2.96802V11.9446C14.873 12.7467 14.6647 13.3534 14.248 13.7649C13.8314 14.1816 13.2168 14.3899 12.4043 14.3899H3.45898ZM7.53711 10.3274C7.63086 10.4836 7.77148 10.5644 7.95898 10.5696C8.14648 10.5748 8.28971 10.4941 8.38867 10.3274L10.9746 5.90552C11.0788 5.72843 11.0944 5.55656 11.0215 5.38989C10.9486 5.21802 10.8184 5.13208 10.6309 5.13208H5.29492C5.10742 5.13208 4.97721 5.22062 4.9043 5.39771C4.83138 5.56958 4.8444 5.73885 4.94336 5.90552L7.53711 10.3274Z" fill="#A3A3A3" />
  </svg>
}

export default FaqQuestion;
