import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./testimonials-alt.module.css";

const TestimonialsAlt = ({ block, title, left_bg, items, right_bg, right_testimonial }) => {
  const rightBg = {
    data: block?.rightBackgroundImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block?.rightBackgroundImage?.altText || ``,
  }

  const testinonialImage = {
    data: block?.testimonial?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block?.testimonial?.featuredImage?.node?.altText || ``,
  }

  return (
    <>
      <section className={`${s.testimonials}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-5 d-flex flex-column justify-content-center">
              {!!block?.title && <h2 className={`${s.testimonialsTitle}`}>{parse(block?.title)}</h2>}
              <div className={`${s.testimonialsWrapper}`} style={{ backgroundImage: `url(${left_bg})` }}>
                {block?.leftItems.map((t, i) => {
                  const image = {
                    data: t.image?.localFile?.childImageSharp?.gatsbyImageData,
                    alt: t.image?.altText || ``,
                  }
                  return (
                    <div className={`${t.className ? t.className : ''} ${s.testimonialItem}`} key={`testimonial-${i}`}>
                      {!!image.data && <div className={`${s.testimonialMedia} text-center`}>
                        <GatsbyImage
                          placeholder="none"
                          loading="eager"
                          image={image.data}
                          alt={image.alt}
                          className="mw-100" />
                      </div>}
                      <div className={`${s.testimonialsText}`}>{parse(t.text)}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-12 col-xl-7">
              <div className={s.right}>
                {!!rightBg.data &&
                  <GatsbyImage
                    placeholder="none"
                    loading="eager"
                    image={rightBg.data}
                    alt={rightBg.alt}
                    className={s.bg} />
                }
                <div className={s.testimonialWrapper}>
                  <div className={s.imageWrapper}>
                    {!!testinonialImage.data &&
                      <GatsbyImage
                        placeholder="none"
                        loading="eager"
                        image={testinonialImage.data}
                        alt={testinonialImage.alt}
                        className={`mw-100`} />
                    }
                  </div>
                  <div className={s.text}>{parse(block?.testimonial?.testimonial?.review)}</div>
                  {!!block?.testimonial?.testimonial?.author && <h4>{parse(block?.testimonial?.testimonial?.author)}</h4>}
                  {!!block?.testimonial?.testimonial?.position && <h6>{parse(block?.testimonial?.testimonial?.position)}</h6>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialsAlt;
