// extracted by mini-css-extract-plugin
export var badgeItem = "testimonials-module--badge-item--3d74d";
export var badgeText = "testimonials-module--badge-text--b6d2a";
export var badgeTitle = "testimonials-module--badge-title--5b931";
export var bg = "testimonials-module--bg--5f020";
export var moreLink = "testimonials-module--more-link--6b87d";
export var testimonialAuthor = "testimonials-module--testimonial-author--94657";
export var testimonialBadge = "testimonials-module--testimonial-badge--18ee4";
export var testimonialBadgeContent = "testimonials-module--testimonial-badge-content--4e310";
export var testimonialBadges = "testimonials-module--testimonial-badges--2342c";
export var testimonialMedia = "testimonials-module--testimonial-media--a1ce8";
export var testimonialPosition = "testimonials-module--testimonial-position--96937";
export var testimonials = "testimonials-module--testimonials--1db1f";
export var testimonialsTag = "testimonials-module--testimonials-tag--5c1b3";
export var testimonialsText = "testimonials-module--testimonials-text--73588";
export var testimonialsTitle = "testimonials-module--testimonials-title--68b65";