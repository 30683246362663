// extracted by mini-css-extract-plugin
export var backgroundImage = "qrMenuMaker-module--background-image--1b555";
export var bottomBlock = "qrMenuMaker-module--bottom-block--9b0c2";
export var ctaBtn = "qrMenuMaker-module--cta-btn--1173f";
export var downloadBtn = "qrMenuMaker-module--download-btn--37874";
export var downloadBtnWrapper = "qrMenuMaker-module--download-btn-wrapper--96f5e";
export var downloadText = "qrMenuMaker-module--download-text--7db57";
export var leftCol = "qrMenuMaker-module--left-col--f4379";
export var logoImage = "qrMenuMaker-module--logo-image--91cb8";
export var qrImage = "qrMenuMaker-module--qr-image--c6e5a";
export var qrMakerSection = "qrMenuMaker-module--qr-maker-section--9ee03";
export var sectionTitle = "qrMenuMaker-module--section-title--2735a";
export var subtitle = "qrMenuMaker-module--subtitle--7aa42";
export var topBlock = "qrMenuMaker-module--top-block--7582b";