import React from "react";
import * as s from "./founders.module.css";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const FoundersBlock = ({ block }) => {
  return (
    <>
      <section className={s.ourFoundingContent}>
        {block?.founders?.length > 0 &&
          <div className="container">
            <div className="row">
              {block?.founders?.map((founder, i) => {
                let image = {
                  data: founder?.image?.localFile?.childImageSharp?.gatsbyImageData,
                  alt: founder?.image?.altText || ``,
                }
                return <div className={`${s.teamMember} col-4 col-md-4 col-lg-4 col-sm-4`} key={i}>
                  {!!image.data &&
                    <GatsbyImage
                      key={i}
                      placeholder="none"
                      loading="eager"
                      image={image.data}
                      alt={image.alt}
                      className="mw-100"
                      objectFit="cover"
                      objectPosition="top center"
                    />
                  }
                  <div className={s.memberInfo}>
                    {!!founder.socialUrl
                      ? <a
                        className="text-decoration-none"
                        href="https://www.linkedin.com/in/arsen-stepanyan-50b7431b2/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h4>{parse(founder.name)}</h4>
                      </a>
                      : <h4>{parse(founder.name)}</h4>
                    }
                    {!!founder.position && <p>{parse(founder.position)}</p>}
                  </div>
                </div>
              })}
            </div>
          </div>
        }
      </section>
    </>
  );
};

export default FoundersBlock;
