import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./integrations.module.css";

const Integrations = ({ block }) => {

  return (
    <>
      <section className={`${s.integrations}`}>
        <div className="container">
          <div className='row justify-content-center'>
            <div className='col-xl-10'>
              <div className={`${s.integrationsWrapper}`}>
                {!!block?.title &&
                  <h2 className={`gradient-title ${s.title}`}>{parse(block?.title)}</h2>
                }
                <div className={s.itemsWrapper}>
                  {block?.icons?.map((item, i) => {
                    const icon = {
                      data: item?.localFile?.childImageSharp?.gatsbyImageData,
                      alt: item?.altText || ``,
                    }
                    return (
                      <div key={i}>
                        <GatsbyImage
                          placeholder="none"
                          loading="eager"
                          image={icon.data}
                          alt={icon.alt}
                          className="w-auto mw-100 mh-100" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default Integrations;
