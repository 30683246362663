import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import SlickArrowLeft from "./../../SlickArrowLeft";
import SlickArrowRight from "./../../slickArrowRight";
import * as s from "./features.module.css";

const Features = ({ block }) => {
  const featuresSettingsV1 = {
    dots: true,
    arrows: false,
    slidesToShow: 3,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575.9,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className={`${s.featuresBlock} features-block`}>
        <div className="container-md">
          {!!block?.title && (
            <div className="row">
              <div className="col-12">
                <h2 className={`${s.title} gradient-title text-center mt-md-0`}>
                  {parse(block?.title)}
                </h2>
              </div>
            </div>
          )}
          <Slider {...featuresSettingsV1} className={`align-items-start`}>
            {block?.items.map((p, i) => {
              return (
                <div className="px-2 py-4 h-100" key={`feature-item-${i}`}>
                  <div
                    className={`${s.featureItem} h-100 d-flex flex-column align-items-center`}
                  >
                    <div className={`${s.numberContainer} d-flex align-items-center justify-content-center`}>
                      <span><b style={{ fontSize: '40px', color: '#f36200' }}>{i + 1}</b></span>
                    </div>
                    {!!p.title && (
                      <div className={s.featureTitle}>{parse(p.title)}</div>
                    )}
                    {!!p.description && (
                      <div className={s.featureDescription}>
                        {parse(p.description)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Features;
