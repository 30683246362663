import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import * as s from "./image-text.module.css";

const ImageText = ({ block }) => {

  const blockRef = useRef();
  const { enterCount } = useInViewport(blockRef, {}, { disconnectOnLeave: false }, {});

  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  let imageClasses = [
    "col-12",
    "d-flex",
    "justify-content-center",
    "align-items-center",
    "col-md-6",
    "order-2",
    "col-xl-5",
  ];
  let textClasses = ["col-12", "col-md-6", "order-1", "col-xl-5"];
  if (block?.imagePosition === "left") {
    imageClasses.push("order-md-1");
    imageClasses.push("justify-content-xl-start");
    textClasses.push("order-md-2");
  }
  if (block?.imagePosition === "right") {
    imageClasses.push("order-md-2");
    imageClasses.push("justify-content-xl-end");
    textClasses.push("order-md-1");
  }

  let titleClasses = [s.imageTextTitle];
  return (
    <section className={`${s.imageTextBlock} image-text-block`} ref={blockRef}>
      {enterCount > 0 && <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className={imageClasses.join(" ")}>
            {!!image?.data &&
              <GatsbyImage
                placeholder="none"
                loading="eager"
                image={image.data}
                alt={image.alt}
                className="mw-100" />
            }
          </div>
          <div className={textClasses.join(" ")}>
            {!!block?.title && <h2 className={titleClasses.join(" ")}>{parse(block?.title)}</h2>}
            {!!block?.description && <div className={s.imageTextDescription}>{parse(block?.description)}</div>}
          </div>
        </div>
      </div>
      }
    </section>
  );
};

export default ImageText;
