import { Link, graphql, useStaticQuery } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import DemoButton from "../demoButton/demoButton";
import parse from "html-react-parser";
import React from "react";
import * as s from "./singleDeviceBlock.module.css";

const SingleDeviceBlock = ({ block, scrollToForm }) => {
  const bp = useBreakpoint();


  const { wp: { acfOptionsSingleDevice: { singleDevice: { singleDevice: singleDeviceData } } } } = useStaticQuery(graphql`
    query {
      wp {
        acfOptionsSingleDevice {
          singleDevice {
            singleDevice {
              title
              description
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);



  const image = {
    data: singleDeviceData.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: singleDeviceData.image?.altText || ``,
  };

  return (
    <>
      <section className={`${s.contentWithImageBlock}`}>
        <div className="container">
          <div className={`row align-items-center`}>
            <div className="col-lg-6">
              {!!singleDeviceData?.title && <h2 className={`gradient-title ${s.title}`}>{parse(singleDeviceData?.title)}</h2>}
              {!!singleDeviceData?.description && <div className={`${s.description}`}>{parse(singleDeviceData?.description)}</div>}
              {bp?.lg && <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>Schedule a DEMO</DemoButton>}
            </div>
            <div className="col-lg-6 text-center">
              {!!image?.data && <GatsbyImage
                placeholder="none"
                loading="eager"
                image={image.data}
                alt={image.alt}
                className="mw-100" />
              }
              {!bp?.lg &&
                <div className="d-flex justify-content-center mt-2">
                  <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>Schedule a DEMO</DemoButton>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleDeviceBlock;
