import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from "html-react-parser";
import React from "react";
import * as s from "./percents.module.css";

const Percents = ({ block }) => {
  const bp = useBreakpoint();

  return (
    <>
      <section className={`${s.percentBlock}`}>
        <div className="container">
          {!!block?.title && <div className="row">
            <div className="col-12">
              <h2 className={s.title}>
                {parse(block?.title)}
              </h2>
            </div>
          </div>}
          <div className={`row flex-column flex-md-row flex-lg-nowrap justify-content-between mx-0`} style={{ gap: '25px' }}>
            {block?.items?.length > 0 && block?.items?.map((p, i) => {
              return (
                <div className="d-flex flex-column" key={i}>
                  {!!p.percent && <div className={`gradient-title ${s.percent}`}>
                    <span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>{parse(p.percent)}</span>
                  </div>}
                  {!!p?.description && <div className={s.description}>{parse(p?.description)}</div>}
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Percents;
