// extracted by mini-css-extract-plugin
export var bg = "testimonials-alt-module--bg--27e75";
export var imageWrapper = "testimonials-alt-module--image-wrapper--e81c1";
export var right = "testimonials-alt-module--right--be5b6";
export var star = "testimonials-alt-module--star--8f50f";
export var stars = "testimonials-alt-module--stars--d1177";
export var testimonialItem = "testimonials-alt-module--testimonial-item--c6a86";
export var testimonialMedia = "testimonials-alt-module--testimonial-media--cda9b";
export var testimonialWrapper = "testimonials-alt-module--testimonial-wrapper--35e97";
export var testimonials = "testimonials-alt-module--testimonials--d2c1b";
export var testimonialsText = "testimonials-alt-module--testimonials-text--656dd";
export var testimonialsTitle = "testimonials-alt-module--testimonials-title--34c3e";
export var testimonialsWrapper = "testimonials-alt-module--testimonials-wrapper--1b4fc";
export var text = "testimonials-alt-module--text--cf8d0";
export var teжт = "testimonials-alt-module--teжт--cdf25";