import { Link } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from "html-react-parser";
import React from "react";
import DemoButton from "../demoButton/demoButton";
import * as s from "./contentWithIframe2.module.css";

const ContentWithIframe2 = ({ block, scrollToForm }) => {
  const bp = useBreakpoint();


  return (
    <>
      <section className={`${s.contentWithImageBlock} ${!!block?.vertical ? s.vertical : ''}`}>
        <div className="container">
          <div className={`row align-items-center`}>
            {(!!block?.reverse && bp?.lg) &&
              <div className="col-lg-7">
                {!!block?.iframe && parse(block?.iframe)}
              </div>
            }
            <div className="col-lg-5">
              {!!block?.badge && <div className={`d-flex justify-content-start`}><div className="blue-badge">{parse(block?.badge)}</div></div>}
              {!!block?.title && <h2 className={`gradient-title ${s.title}`}>{parse(block?.title)}</h2>}
              {!!block?.description && <div className={`${s.description}`}>{parse(block?.description)}</div>}
              {(bp?.lg && !!block?.button?.url) && <>
                {'#contactForm' == block?.button?.url
                  ? <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>{block?.button?.title}</DemoButton>
                  : <Link to={block?.button?.url} className={'demo-button'} target={block?.button?.target}>{block?.button?.title}</Link>}
              </>
              }
            </div>
            {bp?.lg
              ? <>{!block?.reverse && <div className="col-lg-7 text-right">
                {!!block?.iframe && parse(block?.iframe)}
              </div>}</>
              : <div className="col-lg-7 text-center">
                {!!block?.iframe && parse(block?.iframe)}
                {(!bp?.lg && !!block?.button?.url) && <div className="d-flex justify-content-center mt-2">
                  {'#contactForm' == block?.button?.url
                    ? <DemoButton scrollToForm={scrollToForm} classes={['demo-button']}>{block?.button?.title}</DemoButton>
                    : <Link to={block?.button?.url} className={'demo-button'} target={block?.button?.target}>{block?.button?.title}</Link>}
                </div>}
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentWithIframe2;
