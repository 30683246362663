import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import parse from 'html-react-parser';
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import * as s from './qrMenuMaker.module.css';

const QrMenuMaker = ({ block }) => {

  const blockRef = useRef();
  const { enterCount } = useInViewport(blockRef, {}, { disconnectOnLeave: false }, {});

  const { wp: { acfOptionsQrMenuMaker: { qrMenuMaker: { qrMenuMakerBlock: data } } } } = useStaticQuery(graphql`
    query MyOptionsQrMenuMakerQuery{
      wp {
        acfOptionsQrMenuMaker {
          qrMenuMaker {
            qrMenuMakerBlock {
              title
              subtitle
              cta {
                url
                title
                target
              }
              downloadText
              googlePlayLink {
                url
                title
                target
              }
              googlePlayLogo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              appStoreLink {
                url
                title
                target
              }
              appStoreLogo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              imageDesktop {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
              imageMobile {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100 )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const googlePlayLogo = {
    data: data?.googlePlayLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data?.googlePlayLogo?.altText || ``,
  }

  const appStoreLogo = {
    data: data?.appStoreLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data?.appStoreLogo?.altText || ``,
  }

  const imageDesktop = {
    data: data?.imageDesktop?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data?.imageDesktop?.altText || ``,
  }

  const imageMobile = {
    data: data?.imageMobile?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data?.imageMobile?.altText || ``,
  }

  return (
    <>
      <section className={`${s.qrMakerSection} d-flex position-relative`} ref={blockRef}>
        {enterCount > 0 &&
          <div className="container d-flex">
            <div className="row align-items-center" style={{ flexGrow: 1 }}>
              <div className={`col-xl-7 col-lg-8 position-relative ${s.leftCol}`}>
                <div className={`d-flex align-items-center flex-column flex-lg-row justify-content-center justify-content-lg-start ${s.topBlock}`}>
                  {
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="logo"
                      src="./img/logo.png"
                      className={s.logoImage}
                    />
                  }
                  {!!data?.title && <h2 className={s.sectionTitle}>{parse(data?.title)}</h2>}
                </div>
                <StaticImage
                  placeholder="none"
                  loading="eager"
                  alt="logo"
                  src="./img/qr.png"
                  className={s.qrImage}
                />
                <div className={s.bottomBlock}>
                  {!!data?.subtitle && <h4 className={s.subtitle}>{parse(data?.subtitle)}</h4>}
                  {!!data?.cta?.url && <Link to={data?.cta?.url} target={data?.cta?.target} className={s.ctaBtn}>{data?.cta?.title}</Link>}
                  <div className={s.downloadWrapper}>
                    {!!data?.downloadText && <h5 className={s.downloadText}>{parse(data?.downloadText)}</h5>}
                    <div className={`d-flex ${s.downloadBtnWrapper}`}>
                      {!!data?.googlePlayLink?.url &&
                        <Link to={data?.googlePlayLink?.url} target={data?.googlePlayLink?.target} className={s.downloadBtn}>
                          {!!googlePlayLogo?.data
                            ? <GatsbyImage
                              placeholder="none"
                              loading="eager"
                              image={googlePlayLogo.data}
                              alt={googlePlayLogo.alt}
                            />
                            : <StaticImage
                              placeholder="none"
                              loading="eager"
                              alt="playMarket"
                              src="./img/playmarket.png"
                            />
                          }
                        </Link>
                      }
                      {!!data?.appStoreLink?.url &&
                        <Link to={data?.appStoreLink?.url} target={data?.appStoreLink?.target} className={s.downloadBtn}>
                          {!!appStoreLogo?.data
                            ? <GatsbyImage
                              placeholder="none"
                              loading="eager"
                              image={appStoreLogo.data}
                              alt={appStoreLogo.alt}
                            />
                            : <StaticImage
                              placeholder="none"
                              loading="eager"
                              alt="playMarket"
                              src="./img/appstore.png"
                            />
                          }
                        </Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-5 col-lg-4'>
                {!!imageDesktop?.data && <GatsbyImage
                  placeholder="none"
                  loading="eager"
                  image={imageDesktop.data}
                  alt={imageDesktop.alt}
                  className={`d-none d-lg-flex mw-100`}
                />}
              </div>
              {!!imageMobile?.data && <GatsbyImage
                placeholder="none"
                loading="eager"
                image={imageMobile.data}
                alt={imageMobile.alt}
                className={`d-lg-none ${s.backgroundImage}`}
              />}
            </div>
          </div>
        }
      </section>
    </>
  )
}
export default QrMenuMaker;