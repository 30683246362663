import React from "react";
import parse from "html-react-parser";
import * as s from "./title.module.css";

const Title = ({ block }) => {
  return (
    <>
      {block?.title && <section className={`my-4`}>
        <div className="container">
          {!!block?.useH1
            ? <h1 className={`gradient-title ${s.title}`}>{parse(block.title)}</h1>
            : <h2 className={`gradient-title ${s.title}`}>{parse(block.title)}</h2>
          }
        </div>
      </section>}
    </>
  );
};

export default Title;
