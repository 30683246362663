import parse from "html-react-parser";
import React, { useRef, useState } from "react";
import { useInViewport } from 'react-in-viewport';
import * as s from "./faq.module.css";
import FaqQuestion from "./faqquestion";

const Faq = ({ block }) => {

  const blockRef = useRef();
  const { enterCount } = useInViewport(blockRef, {}, { disconnectOnLeave: false }, {});

  const [opened, setOpened] = useState(null);

  const defaultTitle = <>Frequently Asked <span style={{ backgroundImage: 'linear-gradient(180deg, #F76606 0%, #EF6006 100%)' }}>Questions</span></>;

  const toggleOpened = (i) => {
    if (i == opened) {
      setOpened(null);
    } else {
      setOpened(i);
    }
  }

  return (
    <section className={s.accordionBlock} ref={blockRef}>
      {enterCount > 0 &&
        <div className="container">
          <div className={s.wrapper}>
            {!!block?.title && <div className="row ">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <h2 className={`blog-title gradient-title`}>
                  {parse(block?.title)}
                </h2>
              </div>
            </div>}
            <div className="row">
              <div className={`${s.accordion} col-12`}>
                {!!block?.items &&
                  block?.items.map((q, i) => {
                    return (
                      <FaqQuestion key={`faqquestion-${i}`} question={parse(q.question)} opened={i == opened} handleOpened={() => { toggleOpened(i) }}>
                        {parse(q.answer)}
                      </FaqQuestion>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      }
    </section>
  );
};

export default Faq;
