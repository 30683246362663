import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from "html-react-parser";
import React from "react";
import * as s from "./percent.module.css";

const Percent = ({ block }) => {
  const bp = useBreakpoint();

  return (
    <>
      <section className={`${s.percentBlock} ${!!block?.vertical ? s.vertical : ''}`}>
        <div className="container">
          <div className={`row align-items-center flex-column justify-content-center ${!!block?.vertical ? 'flex-md-column' : 'flex-md-row'}`} style={{columnGap: '25px'}}>
            {!!block.percent && <div className={`gradient-title ${s.percent}`}>
              <span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>{parse(block.percent)}</span>
            </div>}
            {!!block?.description && <div className={s.description}>{parse(block.description)}</div>}
          </div>
        </div>
      </section>
    </>
  );
};

export default Percent;
