import { Link, graphql, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import Slider from "react-slick";
import Post from "../../post/post";
import SlickArrowLeft from "./../../SlickArrowLeft";
import SlickArrowRight from "./../../slickArrowRight";
import * as s from "./news.module.css";

const News = ({ block }) => {

  const blockRef = useRef();
  const { enterCount } = useInViewport(blockRef, {}, { disconnectOnLeave: false }, {});

  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: [date], order: DESC }, limit: 3, skip: 0) {
        nodes {
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          readingTime {
            text
          }
          categories {
            nodes {
              name
              uri
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData( quality: 100 )
                }
              }
            }
          }
        }
      }
    }
  `);

  let posts = [];
  if (!!block?.showFromCategory) {
    if (block?.categoty?.posts?.nodes?.length > 0) {
      posts = block?.categoty?.posts?.nodes;
    } else {
      posts = data.allWpPost.nodes;
    }
  } else {
    posts = data.allWpPost.nodes;
  }

  posts.sort((a, b) => {
    let aDate = new Date(a.date);
    let bDate = new Date(b.date);
    return  bDate.getTime() - aDate.getTime();
  })

  const defaultTitle = <>Our Latest <span style={{ backgroundImage: 'linear-gradient(180deg, #FC6905 0%, #EC5D05 100%)', }}>Blog</span> Posts</>;

  if (!posts.length) {
    return <></>;
  }

  const newsSettings = {
    className: 'equal-slides mb-4',
    dots: true,
    arrows: false,
    slidesToShow: 3,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: false,
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <section className={s.blogBlock} ref={blockRef}>
        {enterCount > 0 &&
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className={`gradient-title ${s.blogTitle}`}>
                  {block?.title !== undefined ? parse(block?.title) : defaultTitle}
                </h2>
              </div>
            </div>
            <Slider {...newsSettings}>
              {posts.map((post, i) => {
                if (i > 2) {
                  return null;
                }
                return (
                  <div className="h-100 p-2" key={post.uri}>
                    <Post post={post} className="shadow-none p-3" />
                  </div>
                );
              })}
            </Slider>
            <div className="d-flex justify-content-center">
              <Link to='/blog' className={s.blogLink}>
                <span>Read More</span>
              </Link>
            </div>
          </div>
        }
      </section>
    </>
  );
};

export default News;
