import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./productsGrid.module.css";

const ProductsGrid = ({ block }) => {
  return (
    <>
      {block?.items?.length > 0 &&
        <section className={`${s.productsGridBlock}`}>
          {!!block?.title && <div className="container">
            <div className="d-flex justify-content-center">
              {/* <span style={{ backgroundImage: 'linear-gradient(90deg, #FF6C02 0.1%, #D84C02 62.75%)' }}>Featured Products</span> */}
              <h3 className="gradient-title mt-0 mb-5 text-center" style={{ fontSize: '48px' }}>{parse(block?.title)}</h3>
            </div>
          </div>}
          <div className="container">
            <div className="d-flex flex-wrap justify-content-around" style={{ gap: '26px 14px' }}>
              {block?.items?.map((item, i) => {
                return <ProductItem block={item} key={i} />
              })}
            </div>
          </div>
        </section>
      }
    </>
  );
};

const ProductItem = ({ block }) => {
  let image = {
    data: block?.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block?.image?.altText || ``,
  }
  return (<>
    <Link to={block.link.url} className={s.productItem}>
      <div className={`${s.productItemImage} position-relative`}>
        {!!image && <GatsbyImage
          placeholder="none"
          loading="eager"
          image={image.data}
          alt={image.alt}
          className="mw-100" />
        }
        <div className={s.productItemTitle}>
          {!!block?.ai && <div className="blue-badge position-absolute" style={{ top: '-9px', right: '10px' }}><span style={{ fontSize: "12px" }}>AI</span> powered</div>}
          {parse(block.title ?? '')}
        </div>
      </div>
      {!!block?.text &&
        <div className={s.productItemText}>
          {parse(block.text)}
        </div>
      }
    </Link>
  </>)
}

export default ProductsGrid;
