import React, { useState } from "react";
import YouTube from "react-youtube";
import * as s from "./youtubeVideo.module.css";

const YoutubeVideo = ({ image, videoId }) => {
  const [opened, setOpened] = useState(false);

  const toggleOpened = e => {
    e.preventDefault();
    setOpened(!opened);
  };

  const youtubeImageUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

  return (
    <section className={s.youtubeVieoBlock}>
      <div className="container mb-4">
        {opened === false && (
          <div onClick={toggleOpened} className={s.youtubeVideoPlaceholder}>
            {!!image ? image : <img src={youtubeImageUrl} loading="lazy" className="mw-100"/>}
          </div>
        )}
        {opened === true && (
          <YouTube
            videoId={videoId}
            loading="lazy"
            iframeClassName="mw-100 w-100 h-100"
            className={`mw-100 w-100 ${s.youtubeVideoWrapper}`}
            opts={{ playerVars: { autoplay: 1 } }}
          />
        )}
      </div>
    </section>
  );
};

export default YoutubeVideo;
