import { Link, graphql, useStaticQuery } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import * as s from "./integrationsGlobal.module.css";

const IntegrationsGlobal = () => {

  const blockRef = useRef();
  const { enterCount } = useInViewport(blockRef, {}, { disconnectOnLeave: false }, {});

  const bp = useBreakpoint();

  const { wp: { acfOptionsIntegrations: { integrations } } } = useStaticQuery(graphql`
    query MyOptionsGlobalIntegrationsQuery {
      wp {
        acfOptionsIntegrations {
          integrations {
            pos {
              title
              icons {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
              link {
                url
                title
                target
              }
            }
            restaurants {
              title
              items {
                link {
                  url
                  title
                  target
                }
                gradient
              }
            }
            delivery {
              title
              icons {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
              link {
                url
                title
                target
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <section className={`${s.partnersBlock}`} ref={blockRef}>
        {enterCount > 0 &&
          <div className="container">
            <div className={`row justify-content-around`} style={{ rowGap: '24px' }}>
              <div className={s.itemGroup}>
                {!!integrations?.pos?.title && <div className={s.itemGroupTitle}>{parse(integrations?.pos?.title)}</div>}
                <div className="d-flex justify-content-around flex-wrap">
                  {integrations?.pos?.icons?.length > 0 && integrations?.pos?.icons?.map((item, i) => {
                    let icon = {
                      data: item?.localFile?.childImageSharp?.gatsbyImageData,
                      alt: item?.altText || ``,
                    };
                    return <GatsbyImage
                      key={i}
                      placeholder="none"
                      loading="eager"
                      image={icon.data}
                      alt={icon.alt}
                      className="mw-100" />
                  })}
                  {integrations?.pos?.link?.url && <Link to={integrations?.pos?.link?.url} target={integrations?.pos?.link?.target} className="more-link mt-2">{integrations?.pos?.link?.title}</Link>}
                </div>
              </div>
              <div className={s.itemGroup}>
                {!!integrations?.restaurants?.title && <div className={s.itemGroupTitle}>{parse(integrations?.restaurants?.title)}</div>}
                <div className="d-flex justify-content-around flex-wrap" style={{ gap: '8px' }}>
                  {integrations?.restaurants?.items?.length > 0 && integrations?.restaurants?.items?.map((item, i) => {
                    if (!item?.link?.url) { return <></> }
                    let style = {}
                    if (!!item?.gradient) {
                      style.backgroundImage = item?.gradient;
                    }
                    return <Link key={i} to={item?.link?.url} target={item?.link?.target} className={s.itemGroupLink} style={style}>{item?.link?.title}</Link>
                  })}
                </div>
              </div>
              <div className={s.itemGroup}>
                {!!integrations?.delivery?.title && <div className={s.itemGroupTitle}>{parse(integrations?.delivery?.title)}</div>}
                <div className="d-flex justify-content-around flex-wrap">
                  {integrations?.delivery?.icons?.length > 0 && integrations?.delivery?.icons?.map((item, i) => {
                    let icon = {
                      data: item?.localFile?.childImageSharp?.gatsbyImageData,
                      alt: item?.altText || ``,
                    };
                    return <GatsbyImage
                      key={i}
                      placeholder="none"
                      loading="eager"
                      image={icon.data}
                      alt={icon.alt}
                      className="mw-100" />
                  })}
                  {integrations?.delivery?.link?.url && <Link to={integrations?.delivery?.link?.url} target={integrations?.delivery?.link?.target} className="more-link mt-2">{integrations?.delivery?.link?.title}</Link>}
                </div>
              </div>
            </div>
          </div>
        }
      </section>
    </>
  );
};

export default IntegrationsGlobal;
