import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import SlickArrowLeft from "../../SlickArrowLeft";
import SlickArrowRight from "../../slickArrowRight";
import * as s from "./featuresSlider.module.css";

const FeaturesSlider = ({ block }) => {

  const featuresSettingsV1 = {
    dots: true,
    arrows: false,
    slidesToShow: 3,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575.9,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className={`${s.featuresBlock} features-block mb-5`}>
        <div className="container-md">
          {!!block?.title && (
            <div className="row">
              <div className="col-12">
                <h2 className={`${s.title} ${s.small} title text-left mt-md-0`}>
                  {parse(block?.title)}
                </h2>
              </div>
            </div>
          )}
          <Slider {...featuresSettingsV1} className={`align-items-start`}>
            {block?.items?.map((p, i) => {

              let image = {
                data: p?.image?.localFile?.childImageSharp?.gatsbyImageData,
                alt: p?.image?.altText || ``,
              }
              return (
                <div className="py-4 h-100" key={`feature-item-${i}`}>
                  <div
                    className={`${s.featureItem} h-100 d-flex flex-column align-items-center`}
                    style={{ backgroundColor: p.backgroundColor }}
                  >
                    {!!block?.showNumbers && <div className={s.number} style={{ color: p?.textColor }}>
                      {i < 10 ? '0' + (i + 1) : (i + 1)}
                    </div>}
                    {!!p.title && (
                      <div className={s.featureTitle} style={{ color: p?.textColor }}>{parse(p.title)}</div>
                    )}
                    {!!p.description && (
                      <div className={s.featureDescription} style={{ color: p?.textColor }}>
                        {parse(p.description)}
                      </div>
                    )}

                    {!!image?.data && (
                      <div
                        className={`${s.iconContainer} d-flex align-items-center justify-content-center`}
                      >
                        <GatsbyImage
                          placeholder="none"
                          loading="eager"
                          image={image.data}
                          alt={image.alt}
                          className="mw-100"
                          style={{ marginTop: '38px' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default FeaturesSlider;
