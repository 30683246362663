import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";

const BannerBlock = ({ block }) => {

  return (
    <>
      <section className="my-5">
        <div className="container my-5 py-0 py-lg-5">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div style={{
                borderRadius: '30px',
                boxShadow: '0px 0px 17px 0px rgba(0, 0, 0, 0.25)',
                padding: '50px 40px 40px'
              }}>
                {!!block?.title &&
                  <h2 className="gradient-title text-center mt-0" style={{ fontSize: 'clamp(24px, 3vw, 40px)', lineHeight: '1.3' }}>
                    {parse(block?.title)}
                  </h2>
                }
                <div className="container d-flex justify-content-center mt-4 pt-lg-2">
                  <Link to={block?.link?.url} target={block?.link?.url} className={`px-3 demo-button`}>{block?.link?.title}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerBlock;
